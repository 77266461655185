import { AppStoreServiceId, ApplicationManifest, EHR_EVENT } from '@getvim-os/types';
import { createContext } from 'react';
import type { Hub, SessionContext } from '@getvim/vim-os-sdk-api';
import { VimAuth } from '@getvim/vim-os-api';
import { InternalVimSDK } from '../../sdk/internalSdk';
import { SHARED_SERVICE_DATA } from '../../types';
import { EhrState, EhrStateWithPII } from '../../types/ehrState';
import { InternalSessionContext } from '../../sdk/internalSessionContextApi';

export type UserMetadata = {
  user: InternalSessionContext['user'];
  organization: SessionContext.SessionContext['organization'];
  manifestSupport: ApplicationManifest;
  ehrVendor: SessionContext.SessionContext['ehrType'];
  deviceId: string;
  bareboneType: string;
  bareboneVersion: string;
  hostname: string;
  userSessionId: string;
};

type AppResourceContext = {
  ehrState: EhrState;
  ehrEvent?: EHR_EVENT;
};

export type AppResourceContextWithPII = {
  ehrState: EhrStateWithPII;
  ehrEvent?: EHR_EVENT;
};

export const VimSdkSharedServiceStateContext = createContext<
  Record<AppStoreServiceId, SHARED_SERVICE_DATA>
>({});

export const VimSDKResourcesContext = createContext<AppResourceContext>({ ehrState: {} });

export const VimSdkAuthContext = createContext<VimAuth>({});

export const VimSdkUserMetadataContext = createContext<Partial<UserMetadata>>({});

export const VimSDKAppStateContext = createContext<Hub.AppStateData>({ isAppOpen: false });

export type ReactVimApi = {
  setActivationStatus: InternalVimSDK['hub']['setActivationStatus'];
  setTooltipText: InternalVimSDK['hub']['setTooltipText'];
  copyToClipboard: InternalVimSDK['utils']['copyToClipboard'];
  autoPopup: InternalVimSDK['hub']['autoPopup'];
  trackAnalytics: InternalVimSDK['trackAnalytics'];
  /**
   * @deprecated - use launchButton instead
   */
  enableLaunchButtons: InternalVimSDK['enableLaunchButtons'];
  launchButton: Pick<
    InternalVimSDK['launchButton'],
    'subscribe' | 'unsubscribe' | 'enableLaunchButtons'
  >;
  ehrActions: InternalVimSDK['ehrActions'];
  sessionContext: InternalVimSDK['sessionContext'];
  resourceUpdate: InternalVimSDK['internalResourceUpdater'];
  resourceUpdater: InternalVimSDK['ehr']['resourceUpdater'];
  pushNotification: InternalVimSDK['hub']['pushNotification'];
  setNotificationsIndicator: InternalVimSDK['hub']['notificationBadge']['set'];
  clearNotificationsIndicator: InternalVimSDK['hub']['notificationBadge']['hide'];
  setDynamicAppSize: InternalVimSDK['hub']['setDynamicAppSize'];
  subscribeAppOpenStatus: (
    cb: (status: Hub.AppStateSubscription['appOpenStatus'] | undefined) => void,
  ) => () => void;
  closeApp: InternalVimSDK['hub']['closeApp'];
};

export const VimSDKApiContext = createContext<ReactVimApi>({
  ehrActions: {} as any,
  trackAnalytics: function (): void {
    throw new Error('Function not implemented.');
  },
  setDynamicAppSize: function (_size: Hub.ApplicationSize): void {
    throw new Error('Function not implemented.');
  },
  setActivationStatus: function (): void {
    throw new Error('Function not implemented.');
  },
  setNotificationsIndicator: function (): void {
    throw new Error('Function not implemented.');
  },
  clearNotificationsIndicator: function (): void {
    throw new Error('Function not implemented.');
  },
  setTooltipText: function (): void {
    throw new Error('Function not implemented.');
  },
  autoPopup: function (): void {
    throw new Error('Function not implemented.');
  },
  pushNotification: {} as any,
  copyToClipboard: function (): Promise<void> {
    throw new Error('Function not implemented.');
  },
  resourceUpdate: {} as InternalVimSDK['internalResourceUpdater'],
  resourceUpdater: {} as InternalVimSDK['ehr']['resourceUpdater'],
  sessionContext: {} as InternalVimSDK['sessionContext'],
  enableLaunchButtons: function (): void {
    throw new Error('Function not implemented.');
  },
  launchButton: {
    subscribe: () => undefined,
    unsubscribe: () => {},
    enableLaunchButtons: () => {},
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  subscribeAppOpenStatus: () => () => {},
  closeApp: function (): void {
    throw new Error('Function not implemented.');
  },
});
