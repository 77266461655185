import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import '@getvim/atomic-ui/assets/styles/main.less';
import { ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import { TelemetryProvider } from '@getvim/opentelemetry-sdk/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GlobalStateProvider } from './stores/GlobalStore';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { RequiredStateWrapper } from './components/RequiredStateWrapper';
import { featureFlagsClient } from './logic/featureFlags';
import App from './components/App';
import './index.less';
import { AppEnv } from './types';
import { getConfig } from './config';

/* eslint-disable @typescript-eslint/no-explicit-any */
const appEnv: AppEnv = (window as any).$vim_environment?.APP_ENV;

const AppSdkWrapper = () => {
  const { service } = getConfig();
  return (
    <TelemetryProvider
      options={{
        serviceName: service,
        env: appEnv,
        enableLogs: appEnv === AppEnv.DEV,
        enableMetrics: appEnv === AppEnv.DEV,
        enableTraces: appEnv === AppEnv.DEV,
      }}
    >
      <VimSDKProvider>
        <ThemeVariablesWrapper theme={themes.vim}>
          <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
            <GlobalStateProvider>
              <RequiredStateWrapper>
                <App />
              </RequiredStateWrapper>
            </GlobalStateProvider>
          </FeatureFlagProvider>
        </ThemeVariablesWrapper>
      </VimSDKProvider>
    </TelemetryProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppSdkWrapper />);
