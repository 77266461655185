import { AppToOSMessageTypes } from '@getvim/vim-os-api';
import { Hub } from '@getvim/vim-os-sdk-api';
import { DisplayPushNotification, OsCommunicator } from '.';
import { AppStateApi } from './appStateApi';

export class HubApi implements Hub.Hub {
  constructor(private incomingOsMessagePort: MessagePort, private osCommunicator: OsCommunicator) {
    this.appState = new AppStateApi(this.incomingOsMessagePort);
    this.pushNotification = new DisplayPushNotification(
      this.incomingOsMessagePort,
      this.osCommunicator,
    );
  }

  public pushNotification: DisplayPushNotification;
  public notificationBadge = {
    set: (counter: number) => {
      this.osCommunicator.sendMessageToOS({
        type: AppToOSMessageTypes.SET_NOTIFICATIONS_INDICATOR,
        payload: counter,
      });
    },
    hide: () => {
      this.osCommunicator.sendMessageToOS({
        type: AppToOSMessageTypes.CLEAR_NOTIFICATIONS_INDICATOR,
        payload: undefined,
      });
    },
  };
  public autoPopup = () => {
    this.osCommunicator.sendMessageToOS({ type: AppToOSMessageTypes.POP_UP, payload: undefined });
  };
  public setActivationStatus = (status: Hub.ActivationStatus) => {
    this.osCommunicator.sendMessageToOS({
      type: AppToOSMessageTypes.SET_ACTIVATION_STATUS,
      payload: status,
    });
  };
  public setTooltipText = (text: string) => {
    this.osCommunicator.sendMessageToOS({
      type: AppToOSMessageTypes.SET_TOOLTIP_TEXT,
      payload: text,
    });
  };
  public setDynamicAppSize = (size: Hub.ApplicationSize) => {
    this.osCommunicator.sendMessageToOS({
      type: AppToOSMessageTypes.SET_DYNAMIC_APP_SIZE,
      payload: size,
    });
  };
  public closeApp = () => {
    this.osCommunicator.sendMessageToOS({
      type: AppToOSMessageTypes.CLOSE_APP,
      payload: undefined,
    });
  };
  public appState: AppStateApi;
}
