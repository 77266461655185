export enum Screens {
  None = 'none',
  Loading = 'loading',
  CloverAssist = 'clover-assist',
}
export interface FullOrganization {
  id: number;
  slug: string;
  tin: string;
  cipherKey: string;
}

export enum AppEnv {
  LOCAL = 'local',
  DEV = 'dev',
  STAGING = 'staging',
  DEMO = 'demo',
  PROD = 'prod',
}
